
const isBrowser = typeof window !== "undefined"

export const getLanguage = () => {
  if (!isBrowser) {
    return 'de'
  }
  return window.location.hostname.endsWith('com') ? 'en' : 'de';
}

