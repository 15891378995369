import { graphql, navigate } from "gatsby";
import React from "react";
import { getLanguage } from "../components/Translate";

export default () => {
  const isBrowser = typeof window !== "undefined"

  const redirect = () => {
    if (!isBrowser) {
      return;
    }
    if (getLanguage() === 'en') {
      navigate('en');
    } else {
      navigate('de');
    }
  }

  return <div>{redirect()}</div>
};

export const pageQuery = graphql`
  query IndexNullTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
      }
    }
  }
`;
